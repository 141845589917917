import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Site web en construction
        </p>
        <a
          className="App-link"
          href="https://www.notion.so/S-bastien-CUVELLIER-35de0f1038be40c7b415fa36e7324131"
          target="_blank"
          rel="noopener noreferrer"
        >
          Consultez l'ancienne version ici
        </a>
      </header>
    </div>
  );
}

export default App;
